<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">DEPARTMENT </span> LIST
    </PageHeader>

    <v-row>
      <v-col>
        <v-btn
          class="ma-2 secondary--text font-weight-bold"
          to="/department_management/newdepartment"
          color="primary"
          v-if="getters_abilities.includes('new_department_access')"
        >
          CREATE NEW DEPARTMENT
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="getters_department"
          class="elevation-2"
          item-key="id"
          disable-pagination
          :loading="isLoaded"
          hide-default-footer
        >
          <template #item.dept_head="{ item }">
            <v-btn
              v-if="item.user"
              @click="changeHeader(item)"
              :disabled="
                !getters_abilities.includes('change_department_head_access')
              "
              class="font-weight-bold"
              plain
            >
              {{ item.user.title_name }}
            </v-btn>
            <v-btn
              :disabled="
                !getters_abilities.includes('change_department_head_access')
              "
              v-else
              @click="changeHeader(item)"
              class="font-weight-bold secondary--text"
              color="primary"
            >
              select head
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-edit-dialog
              single-line
              :disabled="
                !getters_abilities.includes('delete_department_access')
              "
            >
              <v-btn dark right large icon>
                <v-icon dark class="red--text"> mdi-close-circle </v-icon>
              </v-btn>

              <template v-slot:input>
                <v-card flat>
                  <v-card-text class="pa-3 ma-0">
                    Do You Want to Delete <b>{{ item.department_name }}</b> ?
                  </v-card-text>
                  <v-card-actions class="px-0 ma-0">
                    <v-btn
                      color="red"
                      dark
                      block
                      @click="
                        removeDepartmentBtn(item.id, item.department_name)
                      "
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:no-data>
            <span class="subheading font-weight-bold">Not available</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-navigation-drawer
      fixed
      v-model="openHead"
      :permanent="openHead"
      right
      width="40%"
      class="shadow"
      clipped
      elevation-19
    >
      <v-toolbar dark color="primary" class="mt-15" elevation="1">
        <v-toolbar-title>
          Head of Department <v-icon>mdi-minus</v-icon>
          {{ department }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="
            openHead = false;
            hod = null;
          "
          icon
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="mt-5" fluid>
        <template v-if="!response && !success">
          <validation-observer ref="observer" v-slot="{ validate }">
            <v-form @submit.prevent="validate().then(newHodBtn)">
              <v-row>
                <v-col cols="12" v-if="getters_dept_staff.length > 0">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="field"
                  >
                    <v-autocomplete
                      outlined
                      :error-messages="errors"
                      class="rounded-0"
                      :items="getters_dept_staff"
                      item-value="id"
                      v-model="hod"
                      item-text="title_name"
                      clearable
                      label="Staff List"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="12" v-else>
                  <v-alert
                    type="info"
                    text
                    :value="true"
                    transition="fade-transition"
                  >
                    NO STAFF IN THE DEPARTMENT OF {{ department }}
                    OR STAFF ACCOUNTS ARE NOT ACTIVATED
                  </v-alert>
                </v-col>
              </v-row>
              <v-footer color="transparent" app>
                <v-btn
                  v-if="!response && !success"
                  :loading="loading"
                  type="submit"
                  color="success"
                >
                  save
                </v-btn>
              </v-footer>
            </v-form>
          </validation-observer>
        </template>
        <template v-else-if="response && !success">
          <v-alert type="error" :value="true" text>{{ msgBody }} </v-alert>
        </template>
        <template v-else>
          <v-alert type="success" :value="true" text>
            Record Is Updated Successfully
          </v-alert>
        </template>
      </v-container>
    </v-navigation-drawer>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
  import { getCurrentInstance, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import PageHeader from "@/components/slots/PageHeader";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });
  export default {
    components: { PageHeader, Response, ValidationObserver, ValidationProvider },
    setup() {
      const vm = getCurrentInstance();
      const { getDepartment, removeDepartment, signOut, getNewHoD, saveNewHod } =
        useActions([
          "getDepartment",
          "removeDepartment",
          "signOut",
          "getNewHoD",
          "saveNewHod",
        ]);

      const { getters_department, getters_dept_staff, getters_abilities } =
        useGetters([
          "getters_department",
          "getters_dept_staff",
          "getters_abilities",
        ]);

      const departmentTable = reactive({
        openHead: false,
        isLoaded: true,
        headers: [
          { text: "FACULTY NAME", value: "faculty.faculty_name" },
          //{ text: "DEPARTMENT CODE", value: "department_code", align: "center" },
          { text: "DEPARTMENT NAME", value: "department_name" },
          { text: "DEPARTMENT HEAD", value: "dept_head", align: "left" },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        color: "",
        levelItem: "",
        absolute: true,
        overlay: false,
        department: "",
        originalDurationText: 0,
        hod: null,
        deptId: 0,
        response: false,
        success: false,
        loading: false,
      });

      const {
        response,
        department,
        actionResponse,
        msgHeader,
        msgBody,
        msgIcon,
        color,
        overlay,
        openHead,
        hod,
        deptId,
        success,
        loading,
      } = toRefs(departmentTable);

      getDepartment()
        .then(() => {
          departmentTable.isLoaded = false;
        })
        .catch((e) => {
          color.value = "error";
          msgIcon.value = "mdi-close-circle";
          msgHeader.value = "Error";
          switch (e.response.status) {
            case 423:
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
              break;

            default:
              msgBody.value = "Something Went Wrong. Try Again Later";
              break;
          }
        });

      const removeDepartmentBtn = (id, department) => {
        actionResponse.value = false;
        overlay.value = true;
        removeDepartment(id)
          .then(() => {
            overlay.value = false;
            getDepartment()
              .then(() => {
                departmentTable.isLoaded = false;
              })
              .catch((e) => {
                color.value = "error";
                msgIcon.value = "mdi-close-circle";
                msgHeader.value = "Error";
                switch (e.response.status) {
                  case 423:
                    msgBody.value = e.response.data.message;
                    signOut().then(() => {
                      vm.proxy.$router.replace({
                        name: "Login",
                      });
                    });
                    break;

                  default:
                    msgBody.value = "Something Went Wrong. Try Again Later";
                    break;
                }
              })
              .finally(() => (overlay.value = false));
          })
          .catch((e) => {
            overlay.value = false;
            actionResponse.value = true;
            msgIcon.value = "mdi-close-circle";
            msgHeader.value = "Error";
            color.value = "error";
            if (e.response.status === 500) {
              msgHeader.value = "Error";
              msgBody.value =
                department +
                " cannot be deleted.It is used in other component(s)";
            } else if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            } else {
              msgBody.value = "This action is unauthorized";
            }
          })
          .finally(() => (overlay.value = false));
      };

      const changeHeader = (item) => {
        success.value = false;
        response.value = false;
        actionResponse.value = false;
        overlay.value = true;
        department.value = item.department_name;
        deptId.value = item.id;
        let data = { user_id: item.user_id, department_id: item.id };
        getNewHoD(data.department_id)
          .then(() => {
            openHead.value = true;
          })
          .catch((e) => {
            actionResponse.value = true;
            color.value = "error";
            msgIcon.value = "mdi-close-circle";
            msgHeader.value = "Error";
            console.log(e);
            switch (e.response.status) {
              case 403:
                msgBody.value = e.response.data.message;
                break;
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;

              default:
                msgBody.value = "Something Went Wrong. Try Again Later";
                break;
            }
          })
          .finally(() => (overlay.value = false));
      };

      const newHodBtn = (bool) => {
        if (bool) {
          loading.value = true;
          saveNewHod({ user_id: hod.value, department_id: deptId.value })
            .then(() => {
              success.value = true;
            })
            .catch((e) => {
              success.value = false;
              /* color.value = "error";
                                                                                        msgIcon.value = "mdi-close-circle";
                                                                                        msgHeader.value = "Error"; */
              switch (e.response.status) {
                case 403:
                  msgBody.value = e.response.data.message;
                  break;
                case 401:
                  msgBody.value = e.response.data.message;
                  break;
                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;

                default:
                  msgBody.value = "Something Went Wrong. Try Again Later";
                  break;
              }
            })
            .finally(() => {
              loading.value = false;
              response.value = true;
            });
        }
      };

      provide("color", color);

      return {
        ...toRefs(departmentTable),
        getters_department,
        removeDepartmentBtn,
        changeHeader,
        getters_dept_staff,
        newHodBtn,
        getters_abilities,
      };
    },
  };
</script>
<style>
  .mycolor {
    color: #ffc601;
  }
</style>
